import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { endGameApi } from "../../helpers/endgameApi";
import { LeaderboardEntry } from "../../types/leaderboardEntry.interface";
import styles from "./DailyLeaderboard.module.scss";
import moment from "moment";
import cx from "classnames";

interface DailyChallengeLeaderboardProps {
  currentUser: any;
  handleChangeUser: (newUser: any) => void;
  setIsLoggingIn:(isLoggingIn: boolean) => void;
}

type LeaderboardDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;

const DailyChallengeLeaderboard = ({
  currentUser,
  handleChangeUser,
  setIsLoggingIn
}: DailyChallengeLeaderboardProps) => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [currentPot, setCurrentPot] = useState(0);
  const [currentDay, setCurrentDay] = useState<LeaderboardDay>(0);
  const pot = currentPot.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  async function getCurrentPot() {
    try {
      const currentPot = await endGameApi('get', '/daily-challenge/current-pot');
      setCurrentPot(currentPot?.amount || 0);
    } catch(e) {
      console.error("GET CURRENT POT ERROR:", e);
    }
  }

  async function getLeaderboard(date?: string) {
    try {
      let leaderboard;
      if(!!date) {
        leaderboard = await endGameApi('get', `/daily-challenge/leaderboard?date=${date}`);
      } else {
        leaderboard = await endGameApi('get', '/daily-challenge/leaderboard');
      }
      setLeaderboard(leaderboard);
    } catch (e) {
      console.error("GET LEADERBOARD ERROR:", e);
    }
  }
 
  useEffect(() => {
    getLeaderboard();
    getCurrentPot();
  }, []);

  const handleDateClick = (day: LeaderboardDay) => () => {
    setCurrentDay(day);
    if(day === 0) {
      getLeaderboard();
    } else {
      const date = moment().subtract(day, 'day').format('YYYY-MM-DD');
      getLeaderboard(date);
    }
  }

  return (
    <div className={styles.container}>
      <h1>Leaderboard</h1>
      <div className={styles.dates}>
        <div className={cx(styles.link, {[styles.active]: currentDay === 0})} onClick={handleDateClick(0)}>Today</div>
        {([1, 2, 3, 4, 5, 6] as LeaderboardDay[]).map(day => (
          <>
            <div className={styles.separator}> | </div>
            <div className={cx(styles.link, {[styles.active]: currentDay === day})} onClick={handleDateClick(day)}>{moment().subtract(day, 'day').format('MMMM D')}</div>
          </>
        ))}
      </div>
      <div><span className={styles.moneyText}>{`Current Pot: ${pot}`}</span></div>
      <div className={styles.tableHeader}>
        <div className={styles.col1}>
          <h2>User</h2>
        </div>
        <div className={styles.col2}>
          <h2>Result</h2>
        </div>
        <div className={styles.col3}/>
      </div>
      <div className={styles.tableEntries}>
        {leaderboard.map((entry) => {
          return (
            <div className={styles.entry} key={entry.id}>
              <div className={styles.col1}>{entry.username}</div>
              <div className={styles.col2}>{entry.scoreDifferential}</div>
              <div className={styles.col3}><Link to={`/daily/match/${entry.matchId}`}>Game Recap</Link></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DailyChallengeLeaderboard;